<template>
<div>
  <v-dialog max-width="480" persistent v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <div  v-bind="attrs"
            v-on="on" >
        <slot name="button"></slot>
      </div>
    </template>
    <v-card class="pa-8" width="480">
      <div>
        <div class="d-flex align-center justify-space-between">
          <h3 class="titleModal">Create Talent Pool</h3>
<!--          <v-btn class="pa-0 ma-0" text @click="dialog = false" data-testid="cancel-modal">-->
            <v-icon  @click="closeDialog()">mdi-close</v-icon>
<!--          </v-btn>-->
        </div>

        <h4 class="subtitleModal my-4">Save candidates for later in a Talent pool</h4>
      </div>

      <ValidationObserver ref="observer" v-slot="{handleSubmit}">
        <v-form id="talentPool" @submit.prevent="handleSubmit(createTalentPool)">
          <div class="mb-4">
            <div  class="mb-1">
              <small >Name</small>
            </div>
            <ValidationProvider
                name="talent pool name"
                rules="required|max:32"
                v-slot="{ errors }">
              <v-text-field id="talentPoolName"
                            counter="32"
                            solo v-model="talentPoolName"/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
              <span  class="err">{{errorMessage}}</span>
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <div class=" mb-1">
              <small >Description</small>
            </div>
            <ValidationProvider
                name="description"
                rules="required|max:500"
                v-slot="{ errors }">
              <v-textarea id="description" hide-details solo v-model="description" />
              <span v-show="errors" class="err">{{ errors[0] }} </span>
              <span  class="err">{{errorMessage}}</span>
            </ValidationProvider>
            </div>

          <div class="tw-flex actions tw-justify-start sm:tw-justify-end mt-12">
            <base-button type="button" @click="closeDialog()" :depressed="true" id="cancel" class="mr-4" :outlined="true" button-text="Cancel"/>
            <base-button type="submit" :loading="loading" :depressed="true" id="add" :width="'150px'" button-text="Create"/>
          </div>

        </v-form>
      </ValidationObserver>

    </v-card>
  </v-dialog>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import getSiteName from "@/services/utils";
import {mapGetters} from "vuex";
import {addCandidateToATalentPool} from "@/services/api/APIService";
export default {
name: "CreateTalentPoolModal",
  components:{BaseButton},
  data(){
  return{
    dialog: false,
    talentPoolName: "",
    description:"",
    siteName: "",
    loading: false,
    errorMessage:"",
    rules: [v => v.length <= 25 || 'Max 25 characters']
  }
  },
  created() {
  this.siteName = getSiteName()
  },
  computed:{
  ...mapGetters("talentPool",["talentPool"])
  },
  props:{
    moveData:Object
  },
  methods:{
    async createTalentPool(){
      this.loading = true
      let data= {
        site_name: this.siteName,
          name: this.talentPoolName,
          description: this.description
      };
      console.log(data)
      await this.$store.dispatch("talentPool/createTalentPool", data)
          .then(res =>{console.log(res)
            this.loading = this.dialog = false
            this.$displaySnackbar({
              message: "Talent pool created successfully",
              success: true
            })
            this.clearForm()
            this.moveToTalentPool(res.data.id)
            this.$refs.observer.reset()
          })
          .catch(err =>{console.log(err)
            this.loading = false
              this.errorMessage = err.response.data.detail
              this.$displaySnackbar({
                message: err.response.data.detail,
                success: false
              })
              setTimeout(() => {
                this.errorMessage = ""
              }, 3000);
            })
    },
    moveToTalentPool(id){
      this.loading = true
      let siteName = getSiteName()
      if(id){
        let data ={
          email: this.moveData.item.candidate_email,
          site_name: siteName,
          pool_id: this.moveData.pool_id,
          current_pool_id: id
        }
        console.log(data)
        this.$store.dispatch("talentPool/moveCandidateToATalentPool", data)
            .then(res =>{
              this.loading = false
              console.log(res)
              this.$displaySnackbar({message:"Candidate moved successfully", success: true})
              this.dialog = false})
            .catch(err =>{this.loading = false
              console.log(err)
              console.log(err.response.status)
              if(err.response.status === 409){
                this.$displaySnackbar({message: err.response.data.detail, success: false})
                // this.$displaySnackbar({message: 'Only accepts Images', success: false})
              }else {
                this.$displaySnackbar({message: "Something went wrong", success: false})
              }
              this.loading = false
            })
      }else if(this.moveData.item.talent_pool === "Unlisted") {
        let data ={
          email: this.moveData.item.email,
          site_name: siteName,
          pool_id: this.moveData.pool_id
        }
        console.log(data)
        addCandidateToATalentPool(data)
            .then(res => {console.log(res)
              this.loading = false
              this.$displaySnackbar({message:"Candidate moved successfully", success: true})
              this.dialog = false
            })
            .catch(err => {console.log(err)
              this.$displaySnackbar({message: err.response.data.detail, success: false})
              this.loading = false})
      }
      else {
        let data ={
          email: this.moveData.item.email,
          site_name: siteName,
          pool_id: this.moveData.pool_id
        }
        addCandidateToATalentPool(data)
            .then(res => {console.log(res)
              this.loading = false
              this.$displaySnackbar({message:"Candidate moved successfully", success: true})
              this.dialog = false
            })
            .catch(err => {console.log(err)
              this.$displaySnackbar({message: err.response.data.detail, success: false})
              this.loading = false})
      }
    },
    closeDialog() {
      this.dialog = false;
      this.clearForm()
      this.$refs.observer.reset()
    },
    clearForm(){
      this.talentPoolName = ""
      this.description = ""
    },
  }
}
</script>

<style scoped lang="scss">
.custom-class{
  color: white;
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
.titleModal{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;

}
.subtitleModal{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.actions {

  @media (max-width: 350px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
.actions #cancel{
  @media (max-width: 350px) {
    margin-top: -20px;
    margin-left: 16px;
  }
}
.actions #add{
  @media (max-width: 350px) {
    margin-top: 10px;
  }
}
</style>
