<template>
  <div class="applyFlexCenter slot">
    <div class="md:tw-w-4/5 tw-w-full tw-h-60 my-3 tw-text-enumGrey2 cardStyle applyFlexCenter">
        <slot></slot>

    </div>

  </div>
</template>

<script>
export default {
name: "EmptyStateComponent"
}
</script>

<style scoped>
.slot >>> .v-sheet.v-card{
  display: flex;
}
.slot{
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}
.cardStyle {
  box-shadow: 0px 8px 16px #F0F9FF ;
}

</style>